@if (shownInputs['GENERAL_REQUIRED']) {
  <div class="col-12 md:col-6">
    <app-switch-input
      [label]="'Is Required'"
      [name]="'is_required'"
      [viewMode]="viewMode"
      [control]="formGroup?.controls?.GENERAL_REQUIRED"
    ></app-switch-input>
  </div>
}

@if (shownInputs['GENERAL_REQUIRED_TRUE']) {
  <div class="col-12 md:col-6">
    <app-switch-input
      [label]="'Is Required True'"
      [name]="'is_required_true'"
      [viewMode]="viewMode"
      [control]="formGroup?.controls?.GENERAL_REQUIRED_TRUE"
    ></app-switch-input>
  </div>
}

@if (shownInputs['PLACEHOLDER']) {
  <div class="col-12 md:col-6">
    <app-basic-input
      label="Placeholder"
      [placeholder]="'Placeholder'"
      [control]="formGroup?.controls?.PLACEHOLDER"
      [viewMode]="viewMode"
    ></app-basic-input>
  </div>
}
@if (fieldType) {
  <p-divider align="left" class="col-12">
    <b>{{ (fieldType == 'STRING' ? 'TEXT' : fieldType) | humanize }} Options</b>
  </p-divider>
}
@if (shownInputs['GENERAL_MIN']) {
  <div class="col-12 md:col-6">
    <app-number-input
      [label]="'Min Number'"
      [placeholder]="'Min Number'"
      [name]="'min_number'"
      [showButtons]="true"
      [control]="formGroup?.controls?.GENERAL_MIN"
      [type]="'number'"
      [viewMode]="viewMode"
      [maxFractionDigits]="0"
      [max]="formGroup?.controls?.GENERAL_MAX?.value || undefined"
    ></app-number-input>
  </div>
}
@if (shownInputs['GENERAL_MAX']) {
  <div class="col-12 md:col-6">
    <app-number-input
      [label]="'Max Number'"
      [placeholder]="'Max Number'"
      [name]="'max_number'"
      [showButtons]="true"
      [control]="formGroup?.controls?.GENERAL_MAX"
      [type]="'number'"
      [viewMode]="viewMode"
      [maxFractionDigits]="0"
      [min]="formGroup?.controls?.GENERAL_MIN?.value || undefined"
    ></app-number-input>
  </div>
}
@if (
  shownInputs['GENERAL_MIN_LENGTH']
    ? fieldType == 'GROUP_VALUE_LIST'
      ? formGroup?.controls?.GVL_SELECTION_MODE.value
      : true
    : false
) {
  <div class="col-12 md:col-6">
    <app-number-input
      [label]="'Min Length'"
      [placeholder]="'Min Length'"
      [name]="'min_length'"
      [showButtons]="true"
      [control]="formGroup?.controls?.GENERAL_MIN_LENGTH"
      [type]="'number'"
      [viewMode]="viewMode"
      [maxFractionDigits]="0"
      [min]="0"
      [max]="formGroup?.controls?.GENERAL_MAX_LENGTH?.value || undefined"
    ></app-number-input>
  </div>
}
@if (
  shownInputs['GENERAL_MAX_LENGTH']
    ? fieldType == 'GROUP_VALUE_LIST'
      ? formGroup?.controls?.GVL_SELECTION_MODE.value
      : true
    : false
) {
  <div class="col-12 md:col-6">
    <app-number-input
      [label]="'Max Length'"
      [placeholder]="'Max Length'"
      [name]="'max_length'"
      [showButtons]="true"
      [control]="formGroup?.controls?.GENERAL_MAX_LENGTH"
      [type]="'number'"
      [viewMode]="viewMode"
      [maxFractionDigits]="0"
      [min]="formGroup?.controls?.GENERAL_MIN_LENGTH?.value || 0"
    ></app-number-input>
  </div>
}
@if (shownInputs['GENERAL_PATTERN']) {
  <div class="col-12 md:col-6">
    <app-basic-input
      label="Regex Pattern"
      [placeholder]="'Regex Pattern'"
      [control]="formGroup?.controls?.GENERAL_PATTERN"
      [viewMode]="viewMode"
    ></app-basic-input>
  </div>
}

@if (shownInputs['ATTACHMENT_MAX_FILE_SIZE']) {
  <div class="col-12 md:col-6">
    <app-number-input
      [label]="'Max File Size(KB)'"
      [placeholder]="'1000000 (1mb size in bytes)'"
      [name]="'max_file_size'"
      [showButtons]="true"
      [control]="formGroup?.controls?.ATTACHMENT_MAX_FILE_SIZE"
      [type]="'number'"
      [viewMode]="viewMode"
      [maxFractionDigits]="0"
      [min]="1024"
    ></app-number-input>
  </div>
}
@if (shownInputs['ATTACHMENT_FILE_LIMIT']) {
  <div class="col-12 md:col-6">
    <app-number-input
      [label]="'Max Number of Files'"
      [placeholder]="'Max Number of Files'"
      [name]="'max_file_number'"
      [showButtons]="true"
      [control]="formGroup?.controls?.ATTACHMENT_FILE_LIMIT"
      [type]="'number'"
      [viewMode]="viewMode"
      [maxFractionDigits]="0"
      [min]="1"
    ></app-number-input>
  </div>
}
@if (shownInputs['ATTACHMENT_ACCEPT']) {
  <div class="col-12 md:col-6">
    <app-basic-input
      label="Accepted File Types"
      [placeholder]="'Example: image/*'"
      [control]="formGroup?.controls?.ATTACHMENT_ACCEPT"
      [viewMode]="viewMode"
    ></app-basic-input>
  </div>
}
@if (shownInputs['ATTACHMENT_UPLOAD_MODE']) {
  <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Upload Location'"
      [placeholder]="'Select Item'"
      [name]="'view-mode-dropdown'"
      [optionLabel]="'label'"
      [multi]="false"
      [items]="attachmentUploadModes"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.ATTACHMENT_UPLOAD_MODE"
      [badgeView]="false"
      [viewMode]="viewMode"
    ></app-drop-down-input>
  </div>
}
@if (shownInputs['ATTACHMENT_FOLDER'] && formGroup?.controls?.ATTACHMENT_UPLOAD_MODE?.value == 'CUSTOM_FOLDER') {
  <div class="col-12 md:col-6">
    <app-folder-bucket-tree-selector
      label="Attachment Folder"
      [placeholder]="'Attachment Folder'"
      [control]="formGroup?.controls?.ATTACHMENT_FOLDER"
      [viewMode]="viewMode"
    ></app-folder-bucket-tree-selector>
  </div>
}
@if (shownInputs['MCQ_SELECTION_MODE']) {
  <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Type'"
      [placeholder]="'Select Item'"
      [name]="'multi-dropdown'"
      [optionLabel]="'label'"
      [multi]="false"
      [items]="mcqSelectionModes"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.MCQ_SELECTION_MODE"
      [badgeView]="true"
      [viewMode]="viewMode"
    ></app-drop-down-input>
  </div>
}
@if (shownInputs['SINGLE_SELECTION_MODE']) {
  <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Single Selection Mode'"
      [placeholder]="'Select Item'"
      [name]="'view-mode-dropdown'"
      [optionLabel]="'label'"
      [multi]="false"
      [items]="singleSelectionModes"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.SINGLE_SELECTION_MODE"
      [badgeView]="true"
      [viewMode]="viewMode"
    ></app-drop-down-input>
  </div>
}

@if (shownInputs['MCQ_REQUIRE_SCORE'] && formGroup?.controls?.MCQ_SELECTION_MODE?.value == 'scored') {
  <div class="col-12 md:col-6">
    <app-switch-input
      [label]="'Passing Question Required?'"
      [name]="'MCQ_REQUIRE_SCORE'"
      [viewMode]="viewMode"
      [control]="formGroup?.controls?.MCQ_REQUIRE_SCORE"
    ></app-switch-input>
  </div>
}
@if (
  shownInputs['MCQ_PASSING_SCORE'] &&
  formGroup?.controls?.MCQ_SELECTION_MODE?.value == 'scored' &&
  formGroup?.controls?.MCQ_REQUIRE_SCORE?.value == true
) {
  <div class="col-12 md:col-6">
    <app-number-input
      [label]="'Passing Score'"
      [placeholder]="'Passing Score...'"
      [name]="'MCQ_PASSING_SCORE'"
      [showButtons]="true"
      [control]="formGroup?.controls?.MCQ_PASSING_SCORE"
      [type]="'number'"
      [viewMode]="viewMode"
      [maxFractionDigits]="0"
      [min]="0"
    ></app-number-input>
  </div>
}

@if (shownInputs['MCQ_SELECTION_OPTIONS'] && formGroup?.controls?.MCQ_SELECTION_MODE?.value == 'scored') {
  <div class="col-12 md:col-12">
    <app-form-repeater
      [viewMode]="viewMode != 'view' ? 'create' : 'view'"
      [title]="''"
      [label]="'Options'"
      [cols]="formRepeaterFields"
      [control]="formGroup?.controls?.MCQ_SELECTION_OPTIONS"
      [showOptionsAction]="false"
      [showDeleteButton]="viewMode != 'view'"
      [showAddForm]="viewMode != 'view'"
      [showReorder]="true"
      [showSelection]="false"
      [flyMode]="false"
      [showAddFormAsPopup]="false"
    ></app-form-repeater>
  </div>
}
@if (
  shownInputs['LIST_OPTIONS'] &&
  (!shownInputs['MCQ_SELECTION_MODE'] ||
    (shownInputs['MCQ_SELECTION_MODE'] && formGroup?.controls?.MCQ_SELECTION_MODE?.value != 'scored'))
) {
  <div class="col-12">
    <app-list-strings
      label="Options"
      [hideLabel]="false"
      [placeholder]="'Add Option'"
      class="mb-2 col-span-2"
      [formControl]="$any(formGroup?.controls?.LIST_OPTIONS)"
      name="options"
      [viewMode]="viewMode"
    ></app-list-strings>
  </div>
}
@if (shownInputs['NUMBER_MAX_FRACTIONS']) {
  <div class="col-12 md:col-6">
    <app-number-input
      [label]="'Max Fractions'"
      [placeholder]="'Max Fractions'"
      [name]="'max_fractions'"
      [showButtons]="true"
      [control]="formGroup?.controls?.NUMBER_MAX_FRACTIONS"
      [type]="'number'"
      [viewMode]="viewMode"
      [maxFractionDigits]="0"
      [min]="0"
    ></app-number-input>
  </div>
}
@if (shownInputs['NUMBER_INPUT_MODE']) {
  <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Mode'"
      [placeholder]="'Select Item'"
      [name]="'multi-dropdown'"
      [optionLabel]="'label'"
      [multi]="false"
      [items]="numberInputModes"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.NUMBER_INPUT_MODE"
      [badgeView]="true"
      [viewMode]="viewMode"
    ></app-drop-down-input>
  </div>
}
@if (shownInputs['NUMBER_INPUT_CURRENCY'] && formGroup?.controls?.NUMBER_INPUT_MODE?.value == 'currency') {
  <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Currency'"
      [placeholder]="'Select Item'"
      [name]="'multi-dropdown'"
      [optionLabel]="'code'"
      [multi]="false"
      [items]="currencyList"
      [optionValue]="'code'"
      [control]="formGroup?.controls?.NUMBER_INPUT_CURRENCY"
      [badgeView]="false"
      [viewMode]="viewMode"
    >
      <ng-template let-value #selectedItemTemplate>
        <div>
          <span [class]="'currency-flag currency-flag-' + value.codeLowerCase"></span>
          {{ value.code }}
        </div>
      </ng-template>
      <ng-template let-option #itemTemplate>
        <div>
          <span [class]="'currency-flag currency-flag-' + option.codeLowerCase"></span>
          {{ option.code }}
        </div>
      </ng-template>
    </app-drop-down-input>
  </div>
}
@if (shownInputs['MIN_DATE']) {
  <div class="col-12 md:col-6">
    <app-date-input
      [label]="'Min Date'"
      [placeholder]="'2022-11-22'"
      [name]="'MIN_DATE'"
      [showIcon]="true"
      [control]="formGroup?.controls?.MIN_DATE"
      [viewMode]="viewMode"
      [maxDate]="(formGroup?.controls?.MAX_DATE?.value | toDateObj) || null"
    ></app-date-input>
  </div>
}
@if (shownInputs['MAX_DATE']) {
  <div class="col-12 md:col-6">
    <app-date-input
      [label]="'Max Date'"
      [placeholder]="'2022-11-22'"
      [name]="'MAX_DATE'"
      [showIcon]="true"
      [control]="formGroup?.controls?.MAX_DATE"
      [viewMode]="viewMode"
      [minDate]="(formGroup?.controls?.MIN_DATE?.value | toDateObj) || null"
    ></app-date-input>
  </div>
}
@if (shownInputs['DATE_SELECTION_MODE']) {
  <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Date Selection Mode'"
      [placeholder]="'Select Item'"
      [name]="'DATE_SELECTION_MODE'"
      [optionLabel]="'label'"
      [multi]="false"
      [items]="dateSelectionModes"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.DATE_SELECTION_MODE"
      [badgeView]="true"
      [viewMode]="viewMode"
    ></app-drop-down-input>
  </div>
}
@if (shownInputs['DATE_VIEW_MODE']) {
  <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Date View Mode'"
      [placeholder]="'Select Item'"
      [name]="'DATE_VIEW_MODE'"
      [optionLabel]="'label'"
      [multi]="false"
      [items]="dateViewModes"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.DATE_VIEW_MODE"
      [badgeView]="true"
      [viewMode]="viewMode"
    ></app-drop-down-input>
  </div>
}
@if (shownInputs['INPUT_MASK']) {
  <div class="col-12 md:col-6">
    <app-basic-input
      label="Input Mask"
      [placeholder]="'Input Mask'"
      [control]="formGroup?.controls?.INPUT_MASK"
      [viewMode]="viewMode"
    ></app-basic-input>
  </div>
}
@if (shownInputs['RELATION_TYPE']) {
  <div class="col-12 md:col-6">
    <app-target-code-selector
      [label]="'Relation Type'"
      [placeholder]="'Relation Type'"
      [control]="formGroup?.controls?.RELATION_TYPE"
      [viewMode]="'create'"
      [multi]="false"
      [optionLabel]="'name'"
      [optionValue]="'name'"
      [showStatusBadge]="false"
      [customProjectionFields]="['code', 'label', 'name', 'color', 'icon']"
      [badgeView]="true"
      [useCustomBadgeMode]="true"
      [inlineMode]="true"
      [targetTypes]="['RELATION_TYPE']"
      [listBoxMode]="false"
    >
    </app-target-code-selector>
  </div>
}
@if (shownInputs['RELATION_TARGET_TYPE']) {
  <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Relation Target Type'"
      [placeholder]="'Select Item'"
      [name]="'multi-dropdown'"
      [optionLabel]="'label'"
      [multi]="false"
      [items]="targetTypes"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.RELATION_TARGET_TYPE"
      [badgeView]="true"
      [viewMode]="viewMode"
    ></app-drop-down-input>
  </div>
}
@if (shownInputs['RATING_MAX_NUMBER']) {
  <div class="col-12 md:col-6">
    <app-number-input
      [label]="'Max Rating Number'"
      [placeholder]="'Max Rating Number'"
      [name]="'max_rating'"
      [showButtons]="true"
      [control]="formGroup?.controls?.RATING_MAX_NUMBER"
      [type]="'number'"
      [viewMode]="viewMode"
      [maxFractionDigits]="0"
      [min]="0"
    ></app-number-input>
  </div>
}
@if (shownInputs['OUTPUT_IMAGE_FORMAT']) {
  <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Output Image Format'"
      [placeholder]="'Select Item'"
      [name]="'OUTPUT_IMAGE_FORMAT'"
      [optionLabel]="'label'"
      [multi]="false"
      [items]="imageFormatModes"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.OUTPUT_IMAGE_FORMAT"
      [badgeView]="true"
      [viewMode]="viewMode"
    ></app-drop-down-input>
  </div>
}
@if (shownInputs['ASPECT_RATIO']) {
  <div class="col-12 md:col-6">
    <app-number-input
      [label]="'Aspect Ratio'"
      [placeholder]="'Aspect Ratio width/height'"
      [name]="'ASPECT_RATIO'"
      [showButtons]="true"
      [control]="formGroup?.controls?.ASPECT_RATIO"
      [type]="'number'"
      [viewMode]="viewMode"
      [maxFractionDigits]="8"
      [min]="0"
    ></app-number-input>
  </div>
}
@if (shownInputs['MAINTAIN_ASPECT_RATIO']) {
  <div class="col-12 md:col-6">
    <app-switch-input
      [label]="'Maintain Aspect Ratio'"
      [name]="'MAINTAIN_ASPECT_RATIO'"
      [viewMode]="viewMode"
      [control]="formGroup?.controls?.MAINTAIN_ASPECT_RATIO"
    ></app-switch-input>
  </div>
}

@if (shownInputs['GVL_CODE']) {
  <div class="col-12">
    <app-target-code-selector
      [label]="'Global Value List'"
      [placeholder]="'Global Value List'"
      [control]="formGroup?.controls?.GVL_CODE"
      [viewMode]="'create'"
      [multi]="false"
      [optionLabel]="'name'"
      [optionValue]="'code'"
      [showStatusBadge]="false"
      [customProjectionFields]="['code', 'label', 'name']"
      [badgeView]="true"
      [useCustomBadgeMode]="true"
      [inlineMode]="true"
      [targetTypes]="['GLOBAL_VALUE_LIST']"
      [listBoxMode]="false"
    >
    </app-target-code-selector>
  </div>
}
@if (shownInputs['GVL_LIST_MODE']) {
  <div class="col-12 md:col-6">
    <app-switch-input
      [label]="'List Box'"
      [name]="'list_box'"
      [viewMode]="viewMode"
      [control]="formGroup?.controls?.GVL_LIST_MODE"
    ></app-switch-input>
  </div>
}
@if (shownInputs['GVL_SELECTION_MODE']) {
  <div class="col-12 md:col-6">
    <app-switch-input
      [label]="'Multiselect'"
      [name]="'multiselect'"
      [viewMode]="viewMode"
      [control]="formGroup?.controls?.GVL_SELECTION_MODE"
    ></app-switch-input>
  </div>
}
@if (shownInputs['GVL_INLINE_MODE']) {
  <div class="col-12 md:col-6">
    <app-switch-input
      [label]="'Inline'"
      [name]="'multiselect'"
      [viewMode]="viewMode"
      [control]="formGroup?.controls?.GVL_INLINE_MODE"
    ></app-switch-input>
  </div>
}
@if (shownInputs['GVL_SHOW_DESCRIPTION']) {
  <div class="col-12 md:col-6">
    <app-switch-input
      [label]="'Show Descripotion'"
      [name]="'showDescription'"
      [viewMode]="viewMode"
      [control]="formGroup?.controls?.GVL_SHOW_DESCRIPTION"
    ></app-switch-input>
  </div>
}
@if (shownInputs['GVL_SHOW_NUMERIC']) {
  <div class="col-12 md:col-6">
    <app-switch-input
      [label]="'Show Numeric'"
      [name]="'showNumeric'"
      [viewMode]="viewMode"
      [control]="formGroup?.controls?.GVL_SHOW_NUMERIC"
    ></app-switch-input>
  </div>
}

@if (shownInputs['GENERAL_EMAIL']) {
  <div class="col-12 md:col-6">
    <app-switch-input
      [label]="'Is Email'"
      [name]="'is_email'"
      [viewMode]="viewMode"
      [control]="formGroup?.controls?.GENERAL_EMAIL"
    ></app-switch-input>
  </div>
}
<!-- @if (shownInputs['ATTACHMENT_MULTIPLE']) {
  <div class="col-12 md:col-6">
    <app-switch-input
      [label]="'Multiple Files'"
      [name]="'is_multiple_files'"
      [viewMode]="viewMode"
      [control]="formGroup?.controls?.ATTACHMENT_MULTIPLE"
    ></app-switch-input>
  </div>
} -->

@if (shownInputs['GENERAL_USE_DEFAULT_VALUE']) {
  <div class="col-12 md:col-6">
    <app-switch-input
      [label]="'Use Default Value'"
      [name]="'GENERAL_USE_DEFAULT_VALUE'"
      [viewMode]="viewMode"
      [control]="formGroup?.controls?.GENERAL_USE_DEFAULT_VALUE"
    ></app-switch-input>
  </div>
}
@if (shownInputs['GENERAL_VIEW_MODE']) {
  <div class="col-12 md:col-6">
    <app-switch-input
      [label]="'View Mode'"
      [name]="'GENERAL_VIEW_MODE'"
      [viewMode]="viewMode"
      [control]="formGroup?.controls?.GENERAL_VIEW_MODE"
      [trueValue]="'view'"
      [falseValue]="null"
    ></app-switch-input>
  </div>
}

@if (shownInputs['MULTI_SELECT_DEFAULT_OPTION'] && formGroup?.controls?.GENERAL_USE_DEFAULT_VALUE?.value) {
  <div class="col-12 md:col-12">
    <app-drop-down-input
      [label]="'Default Option' + (fieldType == 'MULTISELECT' ? 's' : '')"
      [placeholder]="'Select Item'"
      [name]="'MULTI_SELECT_DEFAULT_OPTION'"
      [optionLabel]="'label'"
      [multi]="fieldType == 'MULTISELECT'"
      [items]="listOptions"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.MULTI_SELECT_DEFAULT_OPTION"
      [badgeView]="false"
      [viewMode]="viewMode"
    ></app-drop-down-input>
  </div>
}
@if (shownInputs['NETWORK_DISABLED_BLOCKS'] && formGroup?.controls?.GENERAL_USE_DEFAULT_VALUE?.value) {
  <div class="col-12 md:col-6">
    <app-drop-down-input
      [label]="'Disabled Blocks'"
      [placeholder]="'Select Item'"
      [name]="'multi-dropdown'"
      [optionLabel]="'label'"
      [multi]="true"
      [items]="disabledBlocks"
      [optionValue]="'value'"
      [control]="formGroup?.controls?.NETWORK_DISABLED_BLOCKS"
      [badgeView]="true"
      [viewMode]="viewMode"
    ></app-drop-down-input>
  </div>
}
@if (shownInputs['NETWORK_DEFAULT_BLOCK_VALUES'] && formGroup?.controls?.GENERAL_USE_DEFAULT_VALUE?.value) {
  <div class="col-12 p-0">
    <app-multi-network-input
      class="grid w-full m-auto"
      label="Default Blocks"
      [fieldType]="fieldType"
      [control]="formGroup?.controls?.NETWORK_DEFAULT_BLOCK_VALUES"
      [disbaledBlocksControl]="formGroup?.controls?.NETWORK_DISABLED_BLOCKS"
      [disabledList]="formGroup?.controls?.NETWORK_DISABLED_BLOCKS.value"
      [viewMode]="viewMode"
    ></app-multi-network-input>
  </div>
}
@if (
  !(this.fieldType == 'IP' || this.fieldType == 'IP6' || this.fieldType == 'IPV4_MASK' || this.fieldType == 'MAC') &&
  shownInputs['DEFAULT_VALUE'] &&
  formGroup?.controls?.GENERAL_USE_DEFAULT_VALUE?.value
) {
  <div class="col-12">
    @if (showDefaultValueComponent) {
      <ng-template
        appDynamicComponent
        [mode]="viewMode"
        [dynamicComponentInfo]="defaultValueComponentObject"
      ></ng-template>
    }
  </div>
}
