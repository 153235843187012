import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  AttachmentUploadModes,
  DataTypeEnum,
  DateSelectionModes,
  DateViewModes,
  DynamicComponentBase,
  DynamicFieldConfig,
  DynamicFieldConfigDefaultValue,
  DynamicFieldTypeOptions,
  FieldDto,
  IDynamicComponent,
  McqSelectionModes,
  NumberInputModes,
  OutputImageFormatModes,
  SingleSelectionModes,
  TargetTypeEnum,
  ValidatorOnlyOptions,
  getBlocks,
  getDynamicFieldObjectOptions,
  getEnumOptions,
  isNullObj,
  locales,
  markFormGroupTouched,
  markFormGroupUntouched
} from '@shared/classes';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { BasicInputComponent } from '../basic-input/basic-input.component';
import { DynamicFieldMap } from '../dynamic-field-list-input/dynamic-field-list-input.component';
import { NumberInputComponent } from '../number-input/number-input.component';
import { customFormGroupValidator } from './options.validator';

@Component({
  selector: 'app-dynamic-field-type-options',
  templateUrl: './dynamic-field-type-options.component.html',
  styleUrls: ['./dynamic-field-type-options.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: DynamicFieldTypeOptionsComponent }],
})
export class DynamicFieldTypeOptionsComponent extends DynamicComponentBase implements OnInit {
  showDefaultValueComponent = true;
  defaultValueComponentObject: IDynamicComponent;
  dataType: DataTypeEnum = DataTypeEnum.Text;
  shownInputs: { [x: string]: boolean } = {};
  formGroup: FormGroup = new FormGroup({
    GENERAL_VIEW_MODE: new FormControl(DynamicFieldConfigDefaultValue.GENERAL_VIEW_MODE),
    GENERAL_USE_DEFAULT_VALUE: new FormControl(DynamicFieldConfigDefaultValue.GENERAL_USE_DEFAULT_VALUE),
    GENERAL_MIN: new FormControl(DynamicFieldConfigDefaultValue.GENERAL_MIN),
    GENERAL_MAX: new FormControl(DynamicFieldConfigDefaultValue.GENERAL_MAX),
    GENERAL_REQUIRED: new FormControl(DynamicFieldConfigDefaultValue.GENERAL_REQUIRED),
    GENERAL_REQUIRED_TRUE: new FormControl(DynamicFieldConfigDefaultValue.GENERAL_REQUIRED_TRUE),
    GENERAL_EMAIL: new FormControl(DynamicFieldConfigDefaultValue.GENERAL_EMAIL),
    GENERAL_MIN_LENGTH: new FormControl(DynamicFieldConfigDefaultValue.GENERAL_MIN_LENGTH),
    GENERAL_MAX_LENGTH: new FormControl(DynamicFieldConfigDefaultValue.GENERAL_MAX_LENGTH),
    GENERAL_PATTERN: new FormControl(DynamicFieldConfigDefaultValue.GENERAL_PATTERN),
    ATTACHMENT_MULTIPLE: new FormControl(DynamicFieldConfigDefaultValue.ATTACHMENT_MULTIPLE),
    ATTACHMENT_MAX_FILE_SIZE: new FormControl(DynamicFieldConfigDefaultValue.ATTACHMENT_MAX_FILE_SIZE),
    ATTACHMENT_ACCEPT: new FormControl(DynamicFieldConfigDefaultValue.ATTACHMENT_ACCEPT),
    ATTACHMENT_FOLDER: new FormControl(DynamicFieldConfigDefaultValue.ATTACHMENT_FOLDER),
    ATTACHMENT_FILE_LIMIT: new FormControl(DynamicFieldConfigDefaultValue.ATTACHMENT_FILE_LIMIT),
    ATTACHMENT_UPLOAD_MODE: new FormControl(DynamicFieldConfigDefaultValue.ATTACHMENT_UPLOAD_MODE),
    MULTI_SELECT_DEFAULT_OPTION: new FormControl(DynamicFieldConfigDefaultValue.MULTI_SELECT_DEFAULT_OPTION),
    LIST_OPTIONS: new FormControl(DynamicFieldConfigDefaultValue.LIST_OPTIONS),
    NUMBER_MAX_FRACTIONS: new FormControl(DynamicFieldConfigDefaultValue.NUMBER_MAX_FRACTIONS),
    MIN_DATE: new FormControl(DynamicFieldConfigDefaultValue.MIN_DATE),
    MAX_DATE: new FormControl(DynamicFieldConfigDefaultValue.MAX_DATE),
    DATE_SELECTION_MODE: new FormControl(DynamicFieldConfigDefaultValue.DATE_SELECTION_MODE),
    DATE_VIEW_MODE: new FormControl(DynamicFieldConfigDefaultValue.DATE_VIEW_MODE),
    INPUT_MASK: new FormControl(DynamicFieldConfigDefaultValue.INPUT_MASK),
    RATING_MAX_NUMBER: new FormControl(DynamicFieldConfigDefaultValue.RATING_MAX_NUMBER),
    RELATION_TYPE: new FormControl(DynamicFieldConfigDefaultValue.RELATION_TYPE),
    RELATION_TARGET_TYPE: new FormControl(DynamicFieldConfigDefaultValue.RELATION_TARGET_TYPE),
    SINGLE_SELECTION_MODE: new FormControl(DynamicFieldConfigDefaultValue.SINGLE_SELECTION_MODE),
    MAINTAIN_ASPECT_RATIO: new FormControl(DynamicFieldConfigDefaultValue.MAINTAIN_ASPECT_RATIO),
    ASPECT_RATIO: new FormControl(DynamicFieldConfigDefaultValue.ASPECT_RATIO),
    OUTPUT_IMAGE_FORMAT: new FormControl(DynamicFieldConfigDefaultValue.OUTPUT_IMAGE_FORMAT),
    NETWORK_DISABLED_BLOCKS: new FormControl(DynamicFieldConfigDefaultValue.NETWORK_DISABLED_BLOCKS),
    NETWORK_DEFAULT_BLOCK_VALUES: new FormControl(DynamicFieldConfigDefaultValue.NETWORK_DEFAULT_BLOCK_VALUES),
    GVL_CODE: new FormControl(DynamicFieldConfigDefaultValue.GVL_CODE),
    GVL_SELECTION_MODE: new FormControl(DynamicFieldConfigDefaultValue.GVL_SELECTION_MODE),
    GVL_INLINE_MODE: new FormControl(DynamicFieldConfigDefaultValue.GVL_INLINE_MODE),
    GVL_LIST_MODE: new FormControl(DynamicFieldConfigDefaultValue.GVL_LIST_MODE),
    GVL_SHOW_DESCRIPTION: new FormControl(DynamicFieldConfigDefaultValue.GVL_SHOW_DESCRIPTION),
    GVL_SHOW_NUMERIC: new FormControl(DynamicFieldConfigDefaultValue.GVL_SHOW_NUMERIC),
    DEFAULT_VALUE: new FormControl(DynamicFieldConfigDefaultValue.DEFAULT_VALUE),
    PLACEHOLDER: new FormControl(DynamicFieldConfigDefaultValue.PLACEHOLDER),
    NUMBER_INPUT_MODE: new FormControl(DynamicFieldConfigDefaultValue.NUMBER_INPUT_MODE),
    NUMBER_INPUT_CURRENCY: new FormControl(DynamicFieldConfigDefaultValue.NUMBER_INPUT_CURRENCY),
    MCQ_SELECTION_MODE: new FormControl(DynamicFieldConfigDefaultValue.MCQ_SELECTION_MODE),
    MCQ_PASSING_SCORE: new FormControl(DynamicFieldConfigDefaultValue.MCQ_PASSING_SCORE),
    MCQ_REQUIRE_SCORE: new FormControl(DynamicFieldConfigDefaultValue.MCQ_REQUIRE_SCORE),
    MCQ_SELECTION_OPTIONS: new FormControl(DynamicFieldConfigDefaultValue.MCQ_SELECTION_OPTIONS),
  });
  private _fieldType: FieldDto.TypeEnum;
  @Input() set fieldType(type: FieldDto.TypeEnum) {
    this._fieldType = type;
    this.onSetFieldType();
    this.control?.clearValidators();
    this.control?.addValidators([customFormGroupValidator(this)]);
    this.control?.updateValueAndValidity();
    markFormGroupUntouched(this.formGroup);
  }
  get fieldType() {
    return this._fieldType;
  }
  @Input() fieldTypeControl: FormControl = null;
  targetTypes = getEnumOptions(TargetTypeEnum);
  dateSelectionModes = getEnumOptions(DateSelectionModes);
  dateViewModes = getEnumOptions(DateViewModes);
  singleSelectionModes = getEnumOptions(SingleSelectionModes);
  imageFormatModes = getEnumOptions(OutputImageFormatModes);
  numberInputModes = getEnumOptions(NumberInputModes);
  mcqSelectionModes = getEnumOptions(McqSelectionModes);
  currencyList = locales.map((item) => {
    return {
      code: item.code,
      locale: item.locale,
      codeLowerCase: item.code.toLowerCase(),
    };
  });
  public readonly attachmentUploadModes = AttachmentUploadModes;
  disabledBlocks = [];
  formRepeaterFields = [
    {
      componentType: BasicInputComponent,
      options: {
        label: 'Label',
        name: 'label',
        control: new FormControl(null, Validators.required),
        dataType: DataTypeEnum.Text,
        showLabelInViewMode: false,
      },
    },
    {
      componentType: NumberInputComponent,
      options: {
        label: 'Score',
        name: 'value',
        control: new FormControl(null, Validators.required),
        dataType: DataTypeEnum.Number,
        inputOptions: {
          numberInput: {
            showButtons: true,
            min: 0,
          },
        },
        showLabelInViewMode: false,
      },
    },
  ];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.linkStatusAndErrors();
    const comparer = (prevValue: any, currValue: any) => {
      // Custom comparison logic
      // Return true if the values are considered equal, false otherwise
      return JSON.stringify(prevValue) == JSON.stringify(currValue); // Example: Strict equality comparison
    };
    this.subs.sink = this.formGroup.valueChanges
      .pipe(
        debounceTime(300), // Adjust the debounce time as needed (in milliseconds)
        distinctUntilChanged(comparer)
      )
      .subscribe((values) => {
        let keys = DynamicFieldTypeOptions[this.fieldType];
        if (keys?.length > 0) {
          let value = {};
          keys.forEach((key) => {
            value[key] = values[key];
          });
          const oldVal = { ...this.control.getRawValue() };
          this.control.patchValue(value);

          if (values[DynamicFieldConfig.GENERAL_USE_DEFAULT_VALUE]) {
            this.formGroup.controls[DynamicFieldConfig.GENERAL_VIEW_MODE].enable();

            if (this.fieldType == 'IP' || this.fieldType == 'IP6' || this.fieldType == 'IPV4_MASK' || this.fieldType == 'MAC') {
              // this.formGroup.controls[DynamicFieldConfig.NETWORK_DEFAULT_BLOCK_VALUES].clearValidators();
              // this.formGroup.controls[DynamicFieldConfig.NETWORK_DEFAULT_BLOCK_VALUES].setValidators(getFieldValidators(value));
              this.formGroup.controls[DynamicFieldConfig.NETWORK_DEFAULT_BLOCK_VALUES].updateValueAndValidity();
              // this.formGroup.controls[DynamicFieldConfig.NETWORK_DISABLED_BLOCKS].clearValidators();
              // this.formGroup.controls[DynamicFieldConfig.NETWORK_DISABLED_BLOCKS].setValidators(getFieldValidators(value));
              this.formGroup.controls[DynamicFieldConfig.NETWORK_DISABLED_BLOCKS].updateValueAndValidity();
            }
            // this.formGroup.controls[DynamicFieldConfig.DEFAULT_VALUE].clearValidators();
            // this.formGroup.controls[DynamicFieldConfig.DEFAULT_VALUE].setValidators(getFieldValidators(value));
            this.formGroup.controls[DynamicFieldConfig.DEFAULT_VALUE].updateValueAndValidity();

            if (this.fieldType == 'MULTISELECT') {
              this.formGroup.controls[DynamicFieldConfig.MULTI_SELECT_DEFAULT_OPTION].clearValidators();
              // this.formGroup.controls[DynamicFieldConfig.MULTI_SELECT_DEFAULT_OPTION].setValidators(getFieldValidators(value));
              this.formGroup.controls[DynamicFieldConfig.MULTI_SELECT_DEFAULT_OPTION].updateValueAndValidity();
            }
            const changedValues = this.getChangedValues(oldVal, value);
            if (changedValues && Object.keys(changedValues)?.length > 0) {
              this.initDefaultValueComponent();
            }
            this.manageValidators(keys, value);

          } else if (!values[DynamicFieldConfig.GENERAL_USE_DEFAULT_VALUE]) {
            this.formGroup.controls[DynamicFieldConfig.GENERAL_VIEW_MODE].reset();
            this.formGroup.controls[DynamicFieldConfig.GENERAL_VIEW_MODE].disable();

            if (this.fieldType == 'IP' || this.fieldType == 'IP6' || this.fieldType == 'IPV4_MASK' || this.fieldType == 'MAC') {
              this.formGroup.controls[DynamicFieldConfig.NETWORK_DEFAULT_BLOCK_VALUES].reset();
              this.formGroup.controls[DynamicFieldConfig.NETWORK_DISABLED_BLOCKS].reset();
            }
            this.formGroup.controls[DynamicFieldConfig.DEFAULT_VALUE].reset();


            if (this.fieldType == 'MULTISELECT') {
              this.formGroup.controls[DynamicFieldConfig.MULTI_SELECT_DEFAULT_OPTION].reset();
              this.formGroup.controls[DynamicFieldConfig.MULTI_SELECT_DEFAULT_OPTION].reset();
            }
            this.manageValidators(keys, value);
          } else {
            this.formGroup.controls[DynamicFieldConfig.DEFAULT_VALUE].clearValidators();
            this.formGroup.controls[DynamicFieldConfig.DEFAULT_VALUE].updateValueAndValidity();
          }
          if (this.fieldType == 'SINGLE_SELECTION' || this.fieldType == 'MULTISELECT') {
            // change this to make realtime changes for validators of fields
            this.manageValidators(keys, value);
          }
        }
      });
    this.subs.sink = this.control.valueChanges.subscribe((values) => {
      this.formGroup.patchValue(values, { onlySelf: true, emitEvent: false });
    });

    this.listOptions = isNullObj(this.formGroup.controls?.[DynamicFieldConfig.LIST_OPTIONS]?.value)
      ? []
      : (this.formGroup.controls?.[DynamicFieldConfig.LIST_OPTIONS]?.value as string[])?.map((x) => {
        return { label: x, value: x };
      });
    this.subs.sink = this.formGroup.controls?.[DynamicFieldConfig.LIST_OPTIONS].valueChanges.subscribe((values) => {
      this.listOptions = isNullObj(values)
        ? []
        : (values as string[])?.map((x) => {
          return { label: x, value: x };
        });
    });
  }
  getChangedValues(prev, curr, excludeObject = { ...ValidatorOnlyOptions, DEFAULT_VALUE: true }) {
    const ret = {};
    if (curr) {
      Object.entries(curr).forEach(([key, value]) => {
        if (value != prev?.[key] && !excludeObject[key]) {
          ret[key] = value;
        }
      });
    }
    return ret;
  }
  setInputOptions() {
    this.fieldType = this.inputOptions.dynamicTypeOptionsInput.fieldType ?? this.fieldType;
    this.fieldTypeControl = this.inputOptions.dynamicTypeOptionsInput.fieldTypeControl ?? this.fieldTypeControl;
    if (this.fieldTypeControl) {
      this.subs.sink = this.fieldTypeControl.valueChanges.subscribe((value) => {
        this.fieldType = value;
      });
    }
  }
  onSetFieldType() {
    this.shownInputs = {};
    this.disabledBlocks = getBlocks(this.fieldType);

    let keys = DynamicFieldTypeOptions[this.fieldType];
    if (keys && keys?.length > 0) {
      let value = {};
      keys.forEach((key) => {
        value[key] = this.control?.value?.[key] ?? this.formGroup.value[key];
      });
      this.control.patchValue(value);
      this.formGroup.patchValue(value, { onlySelf: true, emitEvent: false });
      keys.forEach((key) => {
        this.shownInputs[key] = true;
      });
      this.manageValidators(keys, value);
      this.initDefaultValueComponent();
    }
    // if (this.viewMode != 'view') {
    //   this.formGroup.controls[DynamicFieldConfig.NETWORK_DEFAULT_BLOCK_VALUES].patchValue(null);
    //   this.formGroup.controls[DynamicFieldConfig.NETWORK_DISABLED_BLOCKS].patchValue(null);
    // }
  }
  initDefaultValueComponent() {
    this.defaultValueComponentObject = getDynamicFieldObjectOptions(
      {
        type: this.fieldType,
        name: 'Default Value',
        label: 'Default Value',
        key: 'default_value',
        options: { ...this.control.getRawValue(), PLACEHOLDER: 'Fill Default Value...' },
      },
      DynamicFieldMap,
      null,
      this.formGroup.controls['DEFAULT_VALUE'],
      0,
      this.viewMode
    );
    this.showDefaultValueComponent = false;
    setTimeout(() => {
      this.showDefaultValueComponent = true;
    });
  }
  listOptions = [];
  private linkStatusAndErrors() {
    // Override the markAsTouched method
    const originalMarkAsTouched = this.control.markAsTouched.bind(this.control);
    this.control.markAsTouched = () => {
      originalMarkAsTouched();
      markFormGroupTouched(this.formGroup);
    };
  }
  // Method to update validators
  updateValidators(controlName: string, validators: ValidatorFn[]): void {
    const control = this.formGroup.controls[controlName];
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  // Method to dynamically enable/disable validators
  manageValidators(keys: string[], values: any): void {
    const controlsConfig = {
      [DynamicFieldConfig.LIST_OPTIONS]:
        values?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == McqSelectionModes.scored
          ? []
          : [Validators.required, Validators.minLength(1)],
      [DynamicFieldConfig.MCQ_SELECTION_OPTIONS]:
        values?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == McqSelectionModes.scored
          ? [Validators.required, Validators.minLength(1)]
          : [],
      [DynamicFieldConfig.RELATION_TYPE]: [Validators.required],
      [DynamicFieldConfig.RELATION_TARGET_TYPE]: [Validators.required],
      [DynamicFieldConfig.GVL_CODE]: [Validators.required],
      [DynamicFieldConfig.DEFAULT_VALUE]: values?.[DynamicFieldConfig.GENERAL_USE_DEFAULT_VALUE] ? [Validators.required] : []
      // [DynamicFieldConfig.GENERAL_VIEW_MODE]: values?.[DynamicFieldConfig.GENERAL_USE_DEFAULT_VALUE] ? [Validators.required] : []
    };

    Object.keys(controlsConfig).forEach((controlName) => {
      if (keys.includes(controlName)) {
        this.updateValidators(controlName, controlsConfig[controlName]);
      } else {
        this.updateValidators(controlName, []);
      }
    });
  }
}
